<template>
  <div>
    <b-modal
      id="addTeamNameModal"
      :title="$t('conf.settings.team-name.popup.add-title')"
      :ok-title="$t('shared.button.submit')"
      :cancel-title="$t('shared.button.cancel')"
      no-close-on-backdrop
      @hidden="$emit('close')"
      @ok="submitForm"
    >
      <validation-observer ref="newTeamNameValidation">
        <form
          id="createTeamNameForm"
          @submit.prevent
        >

          <b-row>
            <b-col
              cols="12"
              class="pb-1"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('conf.settings.team-name.form.name')"
                rules="required|min:3|max:10"
              >
                <label for="team-name">
                  {{ $t('conf.settings.team-name.form.name') }}
                </label>
                <b-form-input
                  id="team-name"
                  v-model="newTeamNameForm.name"
                  type="text"
                  minlength="3"
                  maxlength="10"
                  :placeholder="$t('conf.settings.team-name.form.name')"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="pb-1"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('conf.settings.team-name.form.phone')"
                rules="digits:9"
              >
                <label for="phoneNumber">
                  {{ $t('conf.settings.team-name.form.phone') }}
                </label>
                <b-form-input
                  id="phoneNumber"
                  v-model="newTeamNameForm.phoneNumber"
                  type="number"
                  minlength="9"
                  maxlength="9"
                  :placeholder="$t('conf.settings.team-name.form.name')"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
        </form>
      </validation-observer>

    </b-modal>
  </div>
</template>

<script>
import { parseRequestError } from '@/helpers/helpers'
import {
  BCol, BFormInput, BModal, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    BModal,
    BFormInput,
    BRow,
    BCol,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      newTeamNameForm: this.$store.state.settings.emptyTeamName,
    }
  },
  mounted() {
    this.$bvModal.show('addTeamNameModal')
  },
  methods: {
    submitForm(event) {
      event.preventDefault()
      this.$refs.newTeamNameValidation.validate()
        .then(valid => {
          if (!valid) {
            return false
          }

          this.$store.dispatch('settings/addTeamName', this.newTeamNameForm)
            .then(() => {
              this.$bvModal.hide('addTeamNameModal')
              this.$emit('submitted')
              window.toast.notify.success(this.$t('conf.settings.team-name.notify.add.success_title'))
            })
            .catch(err => parseRequestError(err))

          return true
        })
    },
  },
}
</script>
