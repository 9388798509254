<template>
  <div>

    <create-team-name-popup
      v-if="showAddModal"
      @close="showAddModal = false"
      @submitted="refreshData(); showAddModal = false;"
    />

    <b-card
      no-body
      class="mb-0"
    >

      <table-top
        show-add-button
        show-search
        @change:limit="limitChanged"
        @change:query="queryChanged"
        @click:add="showAddModal = true "
      />

      <rc-overlay :show="loading">
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="teamNames"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="loading ? $t('shared.loading') : $t('shared.no-data') "
          :sort-by.sync="query.sortBy"
          :sort-desc.sync="query.isSortDirDesc"
        >

          <!-- Column: Name -->
          <template #cell(name)="data">
            <b-media vertical-align="center">
              <b-link
                :to="{ name: 'settings-team-name-edit', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.name }}
              </b-link>
            </b-media>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">
              <feather-icon
                icon="EditIcon"
                class="cursor-pointer"
                size="16"
                @click="editTeamName(data.item.id)"
              />
              <b-tooltip
                :title="$t('shared.edit')"
                class="cursor-pointer"
                :target="`invoice-row-${data.item.id}-send-icon`"
              />
            </div>

          </template>

        </b-table>
      </rc-overlay>

      <table-bottom
        :query="query"
        :total="total"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BLink, BMedia, BTable, BTooltip,
} from 'bootstrap-vue'
import TableTop from '@/layouts/components/table/TableTop.vue'
import CreateTeamNamePopup from '@/views/settings/TeamName/popups/CreateTeamNamePopup.vue'
import TableBottom from '@/layouts/components/table/TableBottom.vue'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'
import { parseRequestError } from '@/helpers/helpers'

export default {
  components: {
    TableBottom,
    CreateTeamNamePopup,
    TableTop,
    RcOverlay,

    BCard,
    BTable,
    BMedia,
    BLink,
    BTooltip,
  },
  data() {
    return {
      showAddModal: false,
      loading: false,

      total: 0,
      query: {
        sortBy: null,
        isSortDirDesc: false,
        query: null,
        limit: 10,
        page: 1,
      },

      tableColumns: [
        {
          label: this.$t('conf.settings.team-name.name'),
          key: 'name',
          sortable: true,
        },
        {
          label: this.$t('conf.settings.team-name.form.phone'),
          key: 'phoneNumber',
          sortable: true,
        },
        {
          label: this.$t('shared.actions'),
          key: 'actions',
          value: null,
          class: 'actions-column',
        },
      ],
    }
  },
  computed: {
    teamNames() {
      return this.$store.state.settings.teamNames
    },
  },
  watch: {
    query: {
      handler() {
        this.refreshData()
      },
      deep: true,
    },
  },
  created() {
    this.refreshData()
  },
  methods: {
    refreshData() {
      this.loading = true
      const query = {
        q: this.query.query,
        sortField: this.query.sortBy,
        sortDir: this.query.isSortDirDesc ? 'desc' : 'asc',
        limit: this.query.limit,
        page: this.query.page,
      }

      Promise.all([this.$store.dispatch('settings/fetchTeamNames', query)])
        .then(res => {
          if (this.query.page > 1 && res[0].data.items.length === 0) {
            this.query.page = 1
          }

          this.total = res[0].data.total
          this.loading = false
        })
        .catch(err => parseRequestError(err))
    },
    deleteTeamName(id) {
      return id
    },
    editTeamName(id) {
      this.$router.push(`/settings/team-name/edit/${id}`)
    },
    limitChanged(newLimit) {
      this.query.limit = newLimit
    },
    queryChanged(newQuery) {
      this.query.query = newQuery
    },
  },
}
</script>

<style lang="scss">

.actions-column {
  width: 1rem;
}
</style>
